/* eslint-disable indent */
import { ReactNode } from "react";

import c from "classnames";

import { SlapLabelVariants } from "@/types/layout";
import { ButtonVariants } from "@/types/inputFields";
import { usePreventScroll } from "@/hooks/usePreventScroll";
import { useFixedOnIOS } from "@/hooks/useFixedOnIOS";

import Styles from "./popUpDrawer.module.scss";
import { PopUpDrawerButtons } from "./PopUpDrawerButtons";

import { Icon, IconOptions } from "../FormElements/Icon";
import { SlapLabel } from "../FormElements/SlapLabel";
import { SwipeContainer } from "../SwipeContainer";

export type PopUpDrawerProps = {
	children?: React.ReactNode;
	shown?: boolean;
	onAccept?: () => void;
	onAcceptText?: string;
	onDecline?: () => void;
	onDeclineText?: string;
	heading?: string;
	subHeading?: string;
	onClose?: () => void;
	onDeclineIcon?: IconOptions;
	onAlternativeOptionText?: string;
	onAlternativeOptionIcon?: IconOptions;
	onAlternativeOption?: () => void;
	onAcceptLoading?: boolean;
	renderButtons?: boolean;
	wider?: boolean;
	error?: string;
	showConfirm?: boolean;
	confirmMessage?: string | ReactNode;
	onAcceptIsNegative?: boolean;
	Buttons?: () => React.ReactElement;
	overflow?: "visible";
	noPadding?: boolean;
	darkTheme?: boolean;
	className?: string;
	onAcceptDisabled?: boolean;
	closeOnBottom?: boolean;
	isLoading?: boolean;
	isLoadingTitle?: string;
	showConfirmIcon?: boolean;
	headingIsNegative?: boolean;
	stickyButtons?: boolean;
	limitHeight?: boolean;
	widerTablet?: boolean;
};

export const PopUpDrawer = ({
	shown,
	onAccept,
	onAcceptText,
	onDecline,
	onDeclineText,
	onDeclineIcon,
	heading,
	subHeading,
	onClose,
	children,
	onAlternativeOption,
	onAlternativeOptionText,
	onAlternativeOptionIcon,
	onAcceptLoading = false,
	renderButtons = true,
	wider = false,
	error,
	showConfirm,
	confirmMessage,
	onAcceptIsNegative,
	Buttons,
	overflow,
	noPadding,
	darkTheme,
	className,
	onAcceptDisabled,
	closeOnBottom = true,
	isLoading,
	isLoadingTitle,
	showConfirmIcon = false,
	headingIsNegative = false,
	stickyButtons = false,
	limitHeight = false,
	widerTablet = false,
}: PopUpDrawerProps) => {
	usePreventScroll({ modalOpen: !!shown,
id: "popupDrawer" });

	const { containerRef } = useFixedOnIOS({ dontMove: false });

	const declineIcon = onDeclineIcon
		? {
				icon: onDeclineIcon,
		  }
		: undefined;

	const alternativeOptionIcon = onAlternativeOptionIcon
		? { icon: onAlternativeOptionIcon }
		: undefined;

	const acceptButtonProps = onAcceptText
		? {
				onClick: onAccept,
				isWorking: onAcceptLoading,
				children: onAcceptText,
				variant: onAcceptIsNegative
					? ButtonVariants.negative
					: ButtonVariants.default,
				disabled: onAcceptDisabled,
		  }
		: undefined;

	const alternativeButtonProps = onAlternativeOptionText
		? {
				onClick: onAlternativeOption,
				isWorking: onAcceptLoading,
				children: onAlternativeOptionText,
				leftIcon: alternativeOptionIcon,
		  }
		: undefined;

	const closeButtonProps = onDeclineText
		? {
				onClick: onDecline,
				isWorking: onAcceptLoading,
				leftIcon: declineIcon,
				children: onDeclineText,
				variant: darkTheme
					? ButtonVariants.ghostLight
					: ButtonVariants.ghost,
		  }
		: undefined;

	const content = (
		<>
			<SwipeContainer onSwipeDown={() => onClose?.()}>
				<div className={Styles.closeHandle}></div>
			</SwipeContainer>

			{heading && (
				<h2 className={c({ ["invalid"]: headingIsNegative })}>{heading}</h2>
			)}

			{subHeading && <h4>{subHeading}</h4>}

			<div
				className={c(Styles.content, {
					[Styles.noButtons]: !renderButtons,
					[Styles.allowOverflow]: overflow === "visible",
				})}
			>
				{children}
			</div>

			{error && (
				<div className={Styles.slapLabel}>
					<SlapLabel variant={SlapLabelVariants.errors} fullWidth wrap>
						{error}
					</SlapLabel>
				</div>
			)}

			{renderButtons && (
				<PopUpDrawerButtons
					transparent={darkTheme}
					acceptButtonProps={acceptButtonProps}
					alternativeButtonProps={alternativeButtonProps}
					closeButtonProps={closeButtonProps}
					closeOnBottom={closeOnBottom}
					stickyButtons={stickyButtons || limitHeight}
				/>
			)}

			{Buttons && <Buttons />}
		</>
	);

	const confirmContent = (
		<>
			{showConfirmIcon && (
				<div
					className={c("text-center", { [Styles.darkThemeIcon]: darkTheme })}
				>
					<Icon icon="IconTick" width="48" height="48" />
				</div>
			)}

			{typeof confirmMessage === "string" && (
				<h2 className="mobileSize">{confirmMessage}</h2>
			)}

			{typeof confirmMessage === "object" && confirmMessage}

			<PopUpDrawerButtons
				transparent={darkTheme}
				acceptButtonProps={{
					children: "Close",
					onClick: onClose,
					variant: darkTheme
						? ButtonVariants.ghostLight
						: ButtonVariants.default,
				}}
			/>
		</>
	);

	const loadingContent = isLoading
		? (
			<>
				{isLoadingTitle && <h2>{isLoadingTitle}</h2>}

				<div>
					<Icon icon="LoadingSpinner" height="65px" width="65px" />
				</div>
			</>
			)
		: null;

	return (
		// Extra div added, the popup drawer was inheriting flex/grid properties in some cases. This extra div keeps it abstracted from the flow.
		<div>
			{shown && <div className={Styles.background} onClick={onClose}></div>}

			<div
				className={c(Styles.wrapper, "text-center", {
					[Styles.shown]: shown,
					[Styles.wider]: wider,
					[Styles.widerTablet]: widerTablet,
					[Styles.noTopPad]: !loadingContent && !showConfirm,
					[Styles.noPad]: noPadding,
					[Styles.darkTheme]: darkTheme,
					[className || ""]: !!className,
					[Styles.stickyButtons]: stickyButtons || limitHeight, 
					[Styles.limitHeight]: limitHeight
				})}
				id="popupDrawer"
				ref={containerRef}
			>
				{loadingContent
					? loadingContent
					: showConfirm
					? confirmContent
					: content}
			</div>
		</div>
	);
};
